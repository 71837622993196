import NAV_DATA, {
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { UserContext } from '@zola-helpers/client/dist/es/@types';
import { NavItem } from './types';

const {
  SECONDARY: { ALBUMS, PAPER, GUEST_LIST, WEBSITE },
} = NAV_DATA;

enum Parents {
  Paper = 'Invites + Paper',
  GuestList = 'Guest List',
  Website = 'Website',
}

class SecondaryNavData {
  userContext: UserContext | null;

  is2022HolidaysEnabled: boolean;

  constructor(userContext: UserContext | null, is2022HolidaysEnabled = false) {
    this.userContext = userContext;
    this.is2022HolidaysEnabled = is2022HolidaysEnabled;
  }

  // Invites - Pre/post auth
  getInvitesItems(): NavItem[] {
    const saveTheDate = {
      path: '/wedding-planning/save-the-date/shop',
      title: 'Save the dates',
      id: PAPER.SAVE_THE_DATE,
      parent: Parents.Paper,
    };
    const matchingSuites = {
      path: '/wedding-planning/suites/shop',
      title: 'Matching suites',
      id: PAPER.MATCHING_SUITES,
      parent: Parents.Paper,
    };
    const digitalSaveTheDate = {
      path: '/wedding-planning/digital/save-the-date/shop',
      title: 'Digital save the dates',
      id: PAPER.DIGITAL_SAVE_THE_DATE,
      parent: Parents.Paper,
    };
    const invitations = {
      path: '/wedding-planning/invitations/shop',
      title: 'Invitations',
      id: PAPER.INVITATION,
      parent: Parents.Paper,
    };
    const weddingShower = {
      path: '/wedding-planning/bridal-shower/shop',
      title: 'Shower invitations',
      id: PAPER.WEDDING_SHOWER_INVITATION,
      newTag: true,
      parent: Parents.Paper,
    };
    const enclosureCards = {
      path: '/wedding-planning/enclosures/shop',
      title: 'Enclosure cards',
      id: PAPER.DETAILS,
      parent: Parents.Paper,
    };
    const menus = {
      path: '/wedding-planning/menus/shop',
      title: 'Menus',
      id: PAPER.MENU,
      parent: Parents.Paper,
    };
    const programs = {
      path: '/wedding-planning/programs/shop',
      title: 'Programs',
      id: PAPER.PROGRAM,
      parent: Parents.Paper,
    };
    const thankYouCards = {
      path: '/wedding-planning/thank-you-cards/shop',
      title: 'Thank you cards',
      id: PAPER.THANK_YOU,
      parent: Parents.Paper,
    };
    const placeCards = {
      path: '/wedding-planning/place-cards/shop',
      title: 'Place cards',
      id: PAPER.PLACE,
      parent: Parents.Paper,
    };
    const holidayCards = {
      path: '/wedding-planning/holiday-cards/shop',
      title: 'Holiday',
      id: PAPER.HOLIDAY,
      parent: Parents.Paper,
      icon: `${process.env.ASSET_PATH}/static-baby/images/icons/gift.svg`,
    };
    const albums = {
      title: 'Albums',
      path: '/wedding-albums/shop',
      id: 'albums',
      parent: Parents.Paper,
    };
    const matchingSuitesFeaturedArtists = {
      path: '/wedding-planning/suites/shop?featuredArtists=featuredArtist',
      title: 'Our featured artists',
    };

    const items = [];
    const matchingCollectionsChildren = [matchingSuites, matchingSuitesFeaturedArtists];

    const matchingCollectionsParent = {
      ...matchingSuites,
      title: 'Collections',
      children: matchingCollectionsChildren,
      showCaret: true,
    };

    const saveTheDatesParent = {
      ...saveTheDate,
      children: [saveTheDate],
      showCaret: true,
    };

    const invitationsParent = {
      ...invitations,
      children: [
        { ...invitations, title: 'Wedding invitations' },
        { ...weddingShower, title: 'Shower invitations' },
        { ...enclosureCards, title: 'Enclosures' },
      ],
      showCaret: true,
    };

    const dayOfParent = {
      path: '/wedding-planning/day-of',
      title: 'Day-of',
      id: SecondaryNavIdentifier.DAY_OF,
      parent: Parents.Paper,
      children: [placeCards, menus, programs],
      showCaret: true,
    };

    items.push(
      matchingCollectionsParent,
      saveTheDatesParent,
      invitationsParent,
      dayOfParent,
      {
        ...thankYouCards,
        title: 'Thank yous',
      },
      digitalSaveTheDate,
      albums
    );

    if (this.is2022HolidaysEnabled) {
      items.push(holidayCards);
    }

    if (this.userContext?.has_invitation_account) {
      const title = 'Your invites and paper';
      const dstdDropdown = {
        showCaret: true,
        children: [
          {
            path: '/wedding-planning/projects/drafts',
            title: 'Your drafts',
          },
          {
            path: '/wedding-planning/projects/finished',
            title: 'Your orders',
          },
          {
            title: 'Your favorites',
            path: '/favorites/paper',
            position: 3,
          },
        ],
      };

      items.unshift({
        title,
        path: '/wedding-planning/projects/drafts',
        id: PAPER.PROJECTS,
        parent: Parents.Paper,
        ...dstdDropdown,
      });
    }

    return items;
  }

  // Guest List - Post auth
  // could be static
  getGuestListItems(): NavItem[] {
    const events = {
      path: '/wedding/manage/events',
      title: 'Your Events',
      id: GUEST_LIST.EVENTS,
      parent: Parents.GuestList,
    };
    const seatingChart = {
      title: 'Your Seating Chart',
      path: '/wedding/manage/seating',
      id: GUEST_LIST.SEATING,
      parent: Parents.GuestList,
    };
    const hotelBlocks = {
      title: 'Hotel Blocks',
      path: 'https://zola.hotelplanner.com/Group-Rate/?kw=guestnav',
      parent: Parents.GuestList,
    };

    const guestListWithSubitems = {
      path: '/wedding/manage/guests/all',
      title: 'Your Guest List',
      id: GUEST_LIST.ALL_GUESTS,
      parent: Parents.GuestList,
      showCaret: true,
      children: [
        {
          path: '/wedding/manage/guests/all',
          title: 'Manage Guest List',
        },
        {
          path: '/wedding/manage/guests/build',
          title: 'Invite Guests to Events',
        },
        {
          path: '/wedding/manage/guests/envelopes',
          title: 'Address Envelopes',
        },
        {
          path: '/wedding/manage/guests/rsvps/overview',
          title: 'Track RSVPs',
        },
      ],
    };

    return [guestListWithSubitems, events, seatingChart, hotelBlocks];
  }

  // Website - Post auth
  // Could be static
  getWebsiteItems(): NavItem[] {
    const manageWebsite = {
      id: WEBSITE.MANAGE,
      path: '/wedding/manage/website/manage',
      title: 'Your Website',
      parent: Parents.Website,
    };

    const editWebsite = {
      id: WEBSITE.EDIT,
      path: '/wedding/manage/website/pages/home',
      title: 'Edit Pages',
      parent: Parents.Website,
    };

    const viewDesigns = {
      id: WEBSITE.DESIGNS,
      path: '/wedding/manage/website/designs',
      title: 'View Designs',
      parent: Parents.Website,
    };

    return [manageWebsite, editWebsite, viewDesigns];
  }

  // Albums - Pre/post auth
  getAlbumsItems(): NavItem[] {
    const items = [
      {
        title: 'Shop Albums',
        path: '/wedding-albums/shop',
        id: ALBUMS.SHOP,
        parent: PrimaryNavIdentifier.ALBUMS,
      },
      {
        path: '/wedding-albums/select?album_type=classic',
        title: 'Classic Albums',
        id: ALBUMS.CLASSIC,
        parent: PrimaryNavIdentifier.ALBUMS,
      },
      {
        path: '/wedding-albums/select?album_type=premier',
        title: 'Premier Albums',
        id: ALBUMS.PREMIER,
        parent: PrimaryNavIdentifier.ALBUMS,
      },
      {
        path: '/wedding-albums/select?album_type=deluxe',
        title: 'Deluxe Albums',
        id: ALBUMS.DELUXE,
        parent: PrimaryNavIdentifier.ALBUMS,
      },
    ];

    const showOrdersAndDrafts =
      this.userContext?.has_invitation_account &&
      this.userContext?.invitation_account &&
      this.userContext?.invitation_account.photobooks_onboarding_completed;

    if (showOrdersAndDrafts) {
      items.unshift({
        title: 'Your Orders + Drafts',
        path: '/wedding-albums/drafts',
        id: ALBUMS.DRAFTS,
        parent: PrimaryNavIdentifier.ALBUMS,
      });
    }

    return items;
  }
}

export default SecondaryNavData;
